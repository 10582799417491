<template>
  <b-container fluid>
      <financial-list
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :data="list"
        :events="TABLE_EVENTS"
        itemRefKey='id'
        :disabled="loadingPage"
        :items-per-page="itemsPerPage"
        @page-changed="loadList"
        :total="total"
        :no-items-message="translations.billing_reports.reports_list.empty_table"
        @row-download="download"/>
  </b-container>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import translations from '@/translations';
import utils from '@/scripts/tools/utils';
import { formatDate, formatDatetime } from '@/helpers/finance';

export default {
  components: {
    FinancialList,
  },
  name: 'BillingReportsList',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.billing_reports.reports_list.report_id, 'id'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.report_type, 'report_type'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.clients, 'computed_clients'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.start_date, 'start_date'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.end_date, 'end_date'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.sword_provider, 'billing_report_config.billing_provider.name'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.payer, 'billing_report_config.payer_external_id'),
      associateHeaderDataItem(this.translations.billing_reports.reports_list.cadence, 'cadence'),
    ];
    this.TABLE_EVENTS = {
      'row-download': {
        title: this.translations.billing_reports.reports_list.action_download,
        icon: 'download',
      },
    };
  },
  data() {
    return {
      translations: translations.finance,
      list: [],
      loadingPage: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    filters: {
      get() {
        return {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'id',
          order_descending: true,
        };
      },
    },
  },
  async beforeMount() {
    await this.loadList();
  },
  methods: {
    async loadList() {
      this.loadingPage = true;
      try {
        const searchArgs = { ...this.filters };
        const { data } = await this.$store.dispatch('Financial/getBillingReports', searchArgs);

        this.total = data.total;
        this.list = data.billing_reports || [];
        this.list.forEach(report => {
          report.computed_clients = report.clients.map(client => client.client_name).join(', ');
          report.created_at = formatDatetime(report.created_at);
          report.start_date = formatDate(report.start_date);
          report.end_date = formatDate(report.end_date);
          if (!report.billing_report_config) {
            report.billing_report_config = {};
          }
          if (!report.billing_report_config.payer_external_id) {
            report.billing_report_config.payer_external_id = '-';
          }
        });
      } catch (err) {
        this.total = 0;
        this.list = [];

        let errDetail = err;
        if (err.response?.data?.error?.detail) {
          errDetail = err.response.data.error.detail;
        }
        this.$noty.error(`An error occurred loading billing reports: ${errDetail}`);
      }
      this.loadingPage = false;
    },
    async download(reportId, report) {
      try {
        const { data } = await this.$store.dispatch('Financial/downloadBillingReport', { id: reportId });
        utils.downloadFile(data, report.filename);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
  },
};
</script>
