<template>
    <table class="table mb-0">
      <thead class="thead-light">
          <tr>
            <th scope="col" class="col-md-2">{{ translations.table.client }}</th>
            <th scope="col" class="col-md-1">{{ translations.table.group }}</th>
            <th scope="col" class="col-md-2">{{ translations.table.report_type }}</th>
            <th scope="col" class="col-md-1">{{ translations.table.cadence }}</th>
            <th scope="col" class="col-md-1">{{ translations.table.start_date }}</th>
            <th scope="col" class="col-md-1">{{ translations.table.end_date }}</th>
            <th scope="col" class="col-md-2">{{ translations.table.sword_provider }}</th>
            <th scope="col" class="col-md-1">{{ translations.table.payer }}</th>
            <th scope="col" class="col-md-2">{{ translations.table.sftp_filepath }}</th>
            <th scope="col" class="col-md-2">{{ translations.table.actions }}</th>
          </tr>
      </thead>
      <tbody>
        <tr v-if="!billingReportConfig.length">
          <td
            aria-colspan="2"
            colspan="2">
            {{ translations.table.empty_table }}
          </td>
        </tr>
        <template v-else>
          <tr v-for="(item, index) in billingReportConfig" :key="index">
            <td class="align-middle mw-20">
              <b-form-group v-if="item.editEnabled"
              class="mx-0 px-0 mw-20"
              :invalid-feedback="handleError('billingReportConfigEditForm.clients')"
              :state="!validateEditRequiredFields || isValid('billingReportConfigEditForm.clients')">
                <multiselect
                  v-model="billingReportConfigEditForm.clients"
                  :options="clients"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  track-by="client_id"/>
              </b-form-group>
              <multiselect v-else
                  v-model="item.clients"
                  :options="item.clients"
                  :multiple="true"
                  :showLabels="false"
                  label="text"
                  :disabled="true"
                  track-by="client_id"/>
            </td>
            <td class="align-middle"><b-form-checkbox :checked="item.group" disabled/></td>
            <td class="align-middle">{{ item.report_type }}</td>
            <td class="align-middle">{{ item.cadence }}</td>
            <td class="align-middle">{{ formatDate(item.start_date) }}</td>
            <td v-if="item.editEnabled" class="align-middle">
              <b-form-group>
                <VueCtkDateTimePicker format="YYYY-MM-DD"
                                      :min-date="minEndDateAvailable"
                                      label="End"
                                      formatted="ll"
                                      :no-button-now="true"
                                      :only-date="true"
                                      v-model="billingReportConfigEditForm.end_date"
                                      id="end-date-list"/>
              </b-form-group>
            </td>
            <td v-else class="align-middle">{{ formatDate(item.end_date) }}</td>
            <td class="align-middle">{{ billingProviderLabelForId(item.billing_provider_id) || '-' }}</td>
            <td class="align-middle">{{ item.payer_external_id || '-' }}</td>
            <td class="align-middle" v-if="item.editEnabled">
              <b-form-group
                class="p-0 m-0"
                :invalid-feedback="handleError('billingReportConfigEditForm.sftp_filepath')"
                :state="!validateEditRequiredFields || isValid('billingReportConfigEditForm.sftp_filepath')">
                <b-select
                v-model="billingReportConfigEditForm.sftp_filepath"
                :options="formatSftpFilepathOptions()"/>
              </b-form-group>
            </td>
            <td class="align-middle" v-else >{{ sftpFilepathOptions[item.sftp_filepath] || item.sftp_filepath }}</td>
            <td class="align-middle">
              <b-button v-if="validateIfIsEmptySameOrFutureDay(item.start_date)"
                type="button"
                class="align-self-end"
                :disabled="loading"
                variant="secondary"
                @click="deleteBillingReportConfig(item.id)">
                <feather type="minus"></feather>
              </b-button>
              <b-button v-if="!validateIfIsEmptySameOrFutureDay(item.start_date)
                && validateIfIsEmptySameOrFutureDay(item.end_date) && !item.editEnabled"
                type="button"
                class="align-self-end"
                :disabled="loading"
                variant="secondary"
                @click="enableEditBillingReportConfig(index)">
                <feather type="edit-2"></feather>
              </b-button>
              <b-button v-else-if="validateIfIsEmptySameOrFutureDay(item.end_date) && item.editEnabled"
                type="button"
                class="align-self-end"
                :disabled="loading"
                variant="secondary"
                @click="updateBillingReportConfig(index)">
                <feather type="save"></feather>
              </b-button>
            </td>
          </tr>
        </template>
        <tr>
          <td class="align-top">
            <b-form-group
              class="mx-0 px-0 mw-20"
              :invalid-feedback="handleError('billingReportConfigForm.clients')"
              :state="!validateRequiredFields || isValid('billingReportConfigForm.clients')">
              <multiselect
                v-model="billingReportConfigForm.clients"
                :options="clients"
                :multiple="true"
                :showLabels="false"
                @select="loadPayers"
                @remove="removePayers"
                label="text"
                track-by="client_id"/>
              </b-form-group>
          </td>
          <td class="align-top">
              <b-form-group>
              <b-form-checkbox
                  v-model="billingReportConfigForm.group"
                  :disabled="disabledGroup"/>
              </b-form-group>
          </td>
          <td class="align-top">
            <b-form-group
              :invalid-feedback="handleError('billingReportConfigForm.report_type')"
              :state="!validateRequiredFields || isValid('billingReportConfigForm.report_type')">
              <b-select
                v-model="billingReportConfigForm.report_type"
                :options="formatReportTypeOptions()"/>
              </b-form-group>
          </td>
          <td class="align-top">
              <b-form-group
              :invalid-feedback="handleError('billingReportConfigForm.cadence')"
              :state="!validateRequiredFields || isValid('billingReportConfigForm.cadence')">
              <b-select
                  v-model="billingReportConfigForm.cadence"
                  :options="formatCadenceOptions()"/>
              </b-form-group>
          </td>
          <td class="align-top">
            <b-form-group
            :invalid-feedback="handleError('billingReportConfigForm.start_date')"
            :state="!validateRequiredFields || isValid('billingReportConfigForm.start_date')">
              <VueCtkDateTimePicker format="YYYY-MM-DD"
                                    :min-date="minStartDateAvailable"
                                    label="Start"
                                    formatted="ll"
                                    :no-button-now="true"
                                    :only-date="true"
                                    v-model="billingReportConfigForm.start_date"
                                    id="start-date-edit"/>
            </b-form-group>
          </td>
          <td class="align-top">
            <b-form-group>
              <VueCtkDateTimePicker format="YYYY-MM-DD"
                                    :min-date="minEndDateAvailable"
                                    label="End"
                                    formatted="ll"
                                    :no-button-now="true"
                                    :only-date="true"
                                    v-model="billingReportConfigForm.end_date"
                                    :disabled="true"
                                    id="end-date-edit"/>
            </b-form-group>
          </td>
          <td class="align-top">
              <b-form-group
              :invalid-feedback="handleError('billingReportConfigForm.billing_provider_id')"
              :state="isValid('billingReportConfigForm.billing_provider_id')">
              <b-select
                  v-model="billingReportConfigForm.billing_provider_id"
                  :options="formatBillingProviderOptions()"/>
              </b-form-group>
          </td>
          <td class="align-top">
            <b-form-group>
              <b-select
                v-model="billingReportConfigForm.payer_external_id"
                :options="payerOptions"/>
            </b-form-group>
          </td>
          <td class="align-top">
            <b-form-group
              :invalid-feedback="handleError('billingReportConfigForm.sftp_filepath')"
              :state="!validateRequiredFields || isValid('billingReportConfigForm.sftp_filepath')">
              <b-select
                v-model="billingReportConfigForm.sftp_filepath"
                :options="formatSftpFilepathOptions()"/>
            </b-form-group>
          </td>
          <td class="align-top">
            <b-button
              type="button"
              class="align-self-end"
              :disabled="loading"
              variant="secondary"
              @click="addBillingReportConfig">
              <feather type="plus"></feather>
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
</template>

<script>
import {
  required, requiredIf,
} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';

const billingProviderNeeded = [ 'External Claims Report' ];

export default {
  components: {
    Multiselect,
  },
  name: 'BillingReportConfig',
  mixins: [ errorHandler ],
  data() {
    return {
      translations: translations.finance.billing_reports.configs,
      billingReportConfig: [],
      billingProviders: [],
      payers: [],
      payerOptions: [ { value: null, text: '' } ],
      payersExternalID: [ null ],
      clients: [],
      clientIDsSavedInDB: [],
      billingReportConfigForm: {
        clients: null,
        group: null,
        report_type: null,
        cadence: null,
        start_date: null,
        end_date: null,
        billing_provider_id: null,
        payer_external_id: null,
        sftp_filepath: null,
      },
      disabledGroup: true,
      validateRequiredFields: false,
      validateEditRequiredFields: false,
      loading: true,
      currentlyEditBillingReport: null,
      billingReportConfigEditForm: {
        clients: null,
        end_date: null,
        sftp_filepath: null,
      },
      sftpFilepathOptions: {
        amwell: 'Amwell partnership (CH)',
      },
    };
  },
  validations() {
    return this.rules;
  },
  async beforeMount() {
    this.loading = true;
    await this.loadBillingReportsConfig();
    await this.loadClient();
    await this.loadBillingProviders();
    this.loading = false;
  },
  computed: {
    rules() {
      return {
        billingReportConfigForm: {
          clients: { required },
          report_type: { required },
          cadence: { required },
          start_date: { required },
          billing_provider_id: {
            requiredIf: requiredIf(() => billingProviderNeeded.includes(this.billingReportConfigForm.report_type)),
          },
          sftp_filepath: { required },
        },
        billingReportConfigEditForm: {
          clients: { required },
          sftp_filepath: { required },
        },
      };
    },
    minStartDateAvailable() {
      return moment().utc().add(1, 'day').format('YYYY-MM-DD');
    },
    minEndDateAvailable() {
      return moment().utc().format('YYYY-MM-DD');
    },
  },
  methods: {
    formatDate,
    async loadBillingReportsConfig() {
      const data = await this.$store.dispatch('Financial/getBillingReportConfigs');
      const { billing_report_configs: billingReportConfigs } = data.data;
      this.clientIDsSavedInDB = [];
      this.billingReportConfig = [];

      this.formatBillingReportConfig(billingReportConfigs);
    },
    async loadClient() {
      const result = await this.$store.dispatch('Core/fetchClients');

      if (result.length > 0) {
        const resultSorted = result.sort((a, b) => a.display_name.localeCompare(b.display_name));

        this.clients = resultSorted.map(client => ({
          client_id: client.id,
          client_name: client.display_name,
          text: `${client.display_name} (ID: ${client.id})`,
        }));
      }
    },
    async loadBillingProviders() {
      this.billingProviders = [ { id: null, name: '' } ];
      await this.$store.dispatch('Financial/prefillBillingProviders');
      const billingProvicersResponse = await this.$store.getters['Financial/getBillingProviders'];
      this.billingProviders = this.billingProviders.concat(billingProvicersResponse);
    },
    async loadPayers(client) {
      try {
        const payersReponse = await this.$store.dispatch('Financial/getPayers', client.client_id);
        if (payersReponse.data?.data?.payers) {
          const { payers } = payersReponse.data.data;

          payers.forEach(payer => {
            const payerIndex = this.payersExternalID.indexOf(payer.payer_external_id);
            if (payerIndex < 0) {
              this.payerOptions.push({
                value: payer.payer_external_id,
                text: payer.payer_external_id,
                count: 1,
                clientIDs: [ client.client_id ],
              });
              this.payersExternalID.push(payer.payer_external_id);
            } else {
              this.payerOptions[payerIndex].count += 1;
              this.payerOptions[payerIndex].clientIDs.push(client.client_id);
            }
          });
        }

        if (this.billingReportConfigForm.clients.length >= 2) {
          this.disabledGroup = false;
        }
      } catch (err) {
        let errDetail = err;
        if (err.response?.data?.error?.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `An error occurred fetching payers: ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    removePayers(client) {
      const newPayerOptions = [ { id: null, text: '' } ];
      const newPayerIds = [ null ];
      this.payerOptions.forEach((payer, index) => {
        if (payer.value != null) {
          const clientIndex = payer.clientIDs.indexOf(client.client_id);
          if (clientIndex >= 0) {
            const newCount = payer.count - 1;
            if (newCount > 0) {
              payer.clientIDs.splice(clientIndex, 1);
              newPayerOptions.push({
                count: newCount,
                clientIDs: payer.clientIDs,
                value: payer.value,
                text: payer.text,
              });
              newPayerIds.push(this.payersExternalID[index]);
            } else if (this.billingReportConfigForm.payer_external_id === this.payersExternalID[index]) {
              this.billingReportConfigForm.payer_external_id = null;
            }
          } else {
            newPayerOptions.push(payer);
            newPayerIds.push(this.payersExternalID[index]);
          }
        }
      });
      this.payerOptions = newPayerOptions;
      this.payersExternalID = newPayerIds;

      if ((this.billingReportConfigForm.clients.length - 1) < 2) {
        this.billingReportConfigForm.group = null;
        this.disabledGroup = true;
      }
    },
    formatReportTypeOptions() {
      return [
        { value: null, text: '' },
        { value: 'External Claims Report', text: 'External Claims Report' },
      ];
    },
    formatCadenceOptions() {
      return [
        { value: null, text: '' },
        { value: 'Monthly', text: 'Monthly' },
        { value: 'Daily', text: 'Daily' },
        { value: 'Weekly', text: 'Weekly' },
        { value: 'Bi-weekly', text: 'Bi-weekly' },
      ];
    },
    formatSftpFilepathOptions() {
      return Object.keys(this.sftpFilepathOptions).map(key => (
        { value: key, text: this.sftpFilepathOptions[key] }
      ));
    },
    formatBillingProviderOptions() {
      return this.billingProviders.map(v => ({ value: v.id, text: v.name }));
    },
    resetBillingReportConfigForm() {
      this.billingReportConfigForm = {
        clients: null,
        group: null,
        report_type: null,
        cadence: null,
        start_date: null,
        end_date: null,
        billing_provider_id: null,
        payer_external_id: null,
        sftp_filepath: null,
      };
      this.validateRequiredFields = false;
      this.disabledGroup = true;
      this.payerOptions = [ { id: null, text: '' } ];
      this.payersExternalID = [ null ];
    },
    async addBillingReportConfig() {
      this.validateRequiredFields = true;
      if (this.$v.billingReportConfigForm.$invalid) {
        return;
      }
      this.loading = true;

      try {
        const res = await this.$store.dispatch('Financial/createBillingReportConfig', this.billingReportConfigForm);
        this.formatBillingReportConfig([ res.data ]);
        this.resetBillingReportConfigForm();
        this.$noty.success(this.translations.notifications.add_success);
      } catch (err) {
        let errDetail = err;
        if (err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.notifications.add_error}: ${errDetail}`;
        this.$noty.error(errMsg);
      }
      this.loading = false;
    },
    formatBillingReportConfig(billingReportsConfiguration) {
      billingReportsConfiguration.forEach(reportConfig => {
        const clientIDs = [];
        const clients = reportConfig.clients.map(client => {
          clientIDs.push(client.client_id);

          return {
            client_id: client.client_id,
            client_name: client.client_name,
            text: `${client.client_name} (ID: ${client.client_id})`,
          };
        });

        this.clientIDsSavedInDB.push(clientIDs);
        this.billingReportConfig.push({
          ...reportConfig,
          clients,
          editEnabled: false,
        });
      });
    },
    billingProviderLabelForId(billingProviderId) {
      const billingProvider = this.billingProviders.find(v => v.id === billingProviderId);
      if (billingProvider) {
        return billingProvider.name;
      }
      return `ERROR_NO_LABEL_${billingProviderId}`;
    },
    validateIfIsEmptySameOrFutureDay(date) {
      if (!date) return true;
      return moment.utc(date).isSameOrAfter(moment(), 'day');
    },
    async deleteBillingReportConfig(billingReportConfigID) {
      const yes = await this.$bvModal.msgBoxConfirm(this.translations.modals.delete_billing_confirmation, {
        okTitle: this.translations.modals.delete_button,
      });
      if (!yes) {
        return;
      }

      this.loading = true;
      try {
        await this.$store.dispatch('Financial/deleteBillingReportConfig', billingReportConfigID);
        await this.loadBillingReportsConfig();
        this.$noty.success(this.translations.notifications.delete_success);
      } catch (err) {
        let errDetail = err;
        if (err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.notifications.delete_error}: ${errDetail}`;

        this.$noty.error(errMsg);
      }
      this.loading = false;
    },
    enableEditBillingReportConfig(index) {
      const endDate = this.billingReportConfig[index].end_date
        ? moment.utc(this.billingReportConfig[index].end_date).format('YYYY-MM-DD')
        : null;
      this.billingReportConfigEditForm = {
        end_date: endDate,
        clients: this.billingReportConfig[index].clients,
        sftp_filepath: this.billingReportConfig[index].sftp_filepath,
      };

      this.validateEditRequiredFields = false;
      if (this.currentlyEditBillingReport !== null) {
        this.billingReportConfig[this.currentlyEditBillingReport].editEnabled = false;
      }
      this.currentlyEditBillingReport = index;
      this.billingReportConfig[index].editEnabled = true;
    },
    async updateBillingReportConfig(index) {
      this.validateEditRequiredFields = true;
      if (this.$v.billingReportConfigEditForm.$invalid) {
        return;
      }

      const clientIDsSavedInDB = this.clientIDsSavedInDB[index];
      const currentClients = this.billingReportConfigEditForm.clients.map(client => client.client_id);
      let haveDeletedClient = false;
      for (let i = 0; i < clientIDsSavedInDB.length; i++) {
        if (!currentClients.includes(clientIDsSavedInDB[i])) {
          haveDeletedClient = true;
          break;
        }
      }
      if (haveDeletedClient) {
        const yes = await this.$bvModal.msgBoxConfirm(this.translations.modals.delete_client_confirmation, {
          okTitle: this.translations.modals.yes_button,
        });

        if (!yes) return;
      }

      this.loading = true;
      try {
        const configID = this.billingReportConfig[index].id;
        const body = {
          end_date: this.billingReportConfigEditForm.end_date,
          clients: this.billingReportConfigEditForm.clients,
          sftp_filepath: this.billingReportConfigEditForm.sftp_filepath,
        };

        await this.$store.dispatch('Financial/updateBillingReportConfig', { configID, body });
        this.$noty.success(this.translations.notifications.update_success);

        this.clientIDsSavedInDB[index] = currentClients;
        this.resetBillingReportConfigUpdate(index, body);
      } catch (err) {
        let errDetail = err;
        if (err.response?.data?.error?.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.notifications.update_error}: ${errDetail}`;

        this.$noty.error(errMsg);
      }
      this.loading = false;
    },
    resetBillingReportConfigUpdate(billingIndex, updateBillingReportConfig) {
      this.billingReportConfig[billingIndex].end_date = updateBillingReportConfig.end_date;
      this.billingReportConfig[billingIndex].sftp_filepath = updateBillingReportConfig.sftp_filepath;
      this.billingReportConfig[billingIndex].clients = updateBillingReportConfig.clients;

      this.billingReportConfigEditForm = {
        end_date: null,
        clients: null,
        sftp_filepath: null,
      };

      this.currentlyEditBillingReport = null;
      this.billingReportConfig[billingIndex].editEnabled = false;
    },
  },
};
</script>

<style lang="scss">
  .mw-20{
    max-width: 20rem;
  }
</style>
