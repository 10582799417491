<template>
  <b-container class="mt-4" fluid>
    <b-row>
        <b-col class="d-flex align-items-center">
            <h3>{{ translations.page_title }}</h3>
        </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-tabs content-class="mt-3">
          <b-tab :title="translations.tabs.reports" active>
            <b-row class="mt-3">
              <b-col cols="12">
                <billing-reports-list />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab :title="translations.tabs.configurations">
            <b-row class="mt-3">
              <b-col cols="12">
                <billing-report-config />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import translations from '@/translations';
import BillingReportConfig from '@/components/Financial/BillingReportConfig.vue';
import BillingReportsList from './reportsList.vue';

export default {
  components: {
    BillingReportsList,
    BillingReportConfig,
  },
  name: 'FinanceBillingReportsPage',
  data() {
    return {
      translations: translations.finance.billing_reports,
    };
  },
};
</script>
